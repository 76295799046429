@charset "UTF-8";
/* ---------------------------- overwrite  */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;600;700&family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Noto+Serif+JP&display=swap');
body {
  font-size: 16px;
  font-family: 'Noto Sans JP' , "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W6", "Hiragino Kaku Gothic Pro", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  line-height: 1.4;
  color: #000;
}
sup {
  font-size: 50%; 
  vertical-align: inherit;
}
.primary-text{
  color: #EB8003;
  .small {
    font-size: 12px;
  }
}
.secondary-text {
  color: #59770B;
}
.dot-text {
  background: top left / 1em .5em repeat-x radial-gradient(circle, black .08em, rgba(0,0,0,0) 0em) ;
  padding-top: .25em;
}
.p {
  font-size: 13px;
  line-height: 1.8;
  text-align: left;
}

/* ---------------------------- パーツ系 */
/* テキストリンク右矢印付き */
a , span {
  &.link-arrow-right{
    position: relative;
    display: inline-block;
    padding-right: 10px;
    &::after {
      content: "";
      display: block;
      width: 7px;
      height: 7px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      transform: rotate(45deg);
      position: absolute;
      top: 50%;
      right: 0px;
      margin-top: -2px;
    }
  }
}

/* ---------------------------- swiper  */
.swiper-wrapper {
  transition-timing-function: linear;
}
.swiper-container-horizontal {
  position: relative;
}
.swiper-button {
  &-prev,
  &-next{
    width: 40px;
    height: 40px;
    margin: 0;
    top: 0;
    transform: none;
    border-radius: 100px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(51, 51, 51, 0.2);
    opacity: 1;
    &::after{
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      position: absolute;
      top: 50%;
      margin-top: -4px;
    }
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
  &-prev {
    left: 0;
    &::after{
      left: 17px;
      transform: rotate(-135deg);
    }
  }
  &-next{
    right: 0;
    &::after{
      right: 17px;
      transform: rotate(45deg);
    }
  }
}

/* ---------------------------- ボタン */
.btn {
  position: relative;
  border-radius: 100px;
  padding: 0px 20px;
  margin: 0;
  line-height: 1.1;
  cursor: pointer;
  vertical-align: middle;
  text-decoration: none !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  box-sizing: border-box;
  -webkit-appearance: none;
  border: none;
  transition: all .3s;
  font-size: 16px;
  padding: 10px 30px;
  height: auto;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  font-weight: 700;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &.btn-disabled,
  &:disabled,
  &:disabled:hover {
    background-color: #ddd !important;
    color: #696969;
    cursor: auto;
    opacity: 0.5 !important;
  }
  .btn-icon {
    position: absolute;
    top: 50%;
    right: 18px;
    width: 0.7em;
    height: 0.7em;
    margin-top: -4px;
    &.icon-cart{
      position: static;
      width: 20px;
      height: 20px;
      margin: 0 8px 0 0;
    }
  }
  &.btn-arrow{
    &-right,
    &-left{
      &::after{
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        position: absolute;
        top: 50%;
        margin-top: -2px;
      }
    }
    &-right{
      &::after{
        transform: rotate(45deg);
        right: 20px;
      }
    }
    &-left{
      &::after{
        transform: rotate(-135deg);
        left: 20px;
      }
    }
  }
  &-default ,
  &-main {
    background: #000;
    color: #fff;
    border: 1px solid #000;
    width: auto;
    font-size: 16px;
    white-space: pre;
    .btn-icon {
      fill: #fff;
    }
    &:hover {
      background: #555;
    }
  }
  &-cart {
    background: #E60012;
    color: #fff;
    white-space: pre;
    .btn-icon {
      fill: #fff;
    }
    &:hover {
      background: #E60012;
    }
    &.btn-arrow-left,
    &.btn-arrow-right {
      &::after {
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
      }
    }
  }
  &-size {
    &-l {
      height: 64px;
    }
    &-m {
      font-size: 14px;
      font-weight: 500;
      padding: 0 20px;
      height: 48px;
      white-space: nowrap;
      min-width: inherit;
      .btn-icon {
        right: 10px;
        margin-top: -4px;
      }
      &.btn-close,
      &.btn-arrow-right {
        padding-right: 25px;
      }    
      &.btn-arrow-left {
        padding-left: 25px;
      }    
    }
    &-s {
      font-size: 11px;
      font-weight: 500;
      padding: 0 15px;
      min-width: inherit;
      height: 32px;
      white-space: nowrap;
      min-width: inherit;
      .btn-icon {
        right: 10px;
        margin-top: -3px;
      }
      &.btn-close,
      &.btn-arrow-right {
        padding-right: 25px;
      }
      &.btn-arrow-left {
        padding-left: 25px;
      }    
    }
  }
  &.is_soldout {
    background-color: #F5F5F5 !important;
    color: #ccc !important;
    cursor: auto !important;
    opacity: 1 !important;
    pointer-events: none !important;
  }
  // 価格表示
  &-price {
    &-value {
      display: inline-block;
      margin-left: 10px;
      font-size: 20px;
    }
    &-label {
      display: inline-block;
      margin-left: 5px;
      font-weight: 400;
      font-size: 11px;
    }
  }
}

input{
  &.btn-arrow-right {
    background-image: url(../img/usr/common/arrow_right_grey.png);
    background-position: right 20px top 50%;
    background-repeat: no-repeat;
    background-size: 5px auto;
    &::after {
      display: none;
    }
    &:hover{
      background-image: url(../img/usr/common/arrow_right_grey.png);
      background-position: right 20px top 50%;
      background-repeat: no-repeat;
      background-size: 5px auto;  
    }
  }
  &.btn-arrow-left {
    background-image: url(../img/usr/common/arrow_left_grey.png);
    background-position: left 20px top 50%;
    background-repeat: no-repeat;
    background-size: 5px auto;
    &::after {
      display: none;
    }
    &:hover{
      background-image: url(../img/usr/common/arrow_left_grey.png);
      background-position: left 20px top 50%;
      background-repeat: no-repeat;
      background-size: 5px auto;  
    }
  }
  &.btn-cart{
    &.btn-arrow-right {
      background-image: url(../img/usr/common/arrow_right_white.png);
    }

    &.btn-arrow-left {
      background-image: url(../img/usr/common/arrow_left_white.png);
    }
  }
}